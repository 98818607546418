<template>
  <div class="top-wrap" :style="isHomeSelected ?  {'background-color': 'rgba(0, 0, 0, 0)'} : {'background-color': '#FFFFFFF'}">
    <div class="main-wrap">
      <img src="../assets/images/header_logo.png" />
      <a-menu :selectedKeys="['/'+$route.path.split('/')[1]]" mode="horizontal" >
          <template v-for="(item, index) in routerList" :key="index">
            <temp>
              <a-menu-item :key="item.name">
                <router-link :to="item.path" v-if="item.meta.isShow" ><span class="top-menu">{{ item.meta?.title }}</span></router-link>
              </a-menu-item>
            </temp>
          </template>
			</a-menu>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'page-header',
  setup() {
    const router = useRouter()
    const routerList = router.getRoutes().filter((v) => v.meta.isShow);
    const isHomeSelected = true;
    return {
      selectedKeys: ref(['1']),
      routerList,
      isHomeSelected, // 控制导航是否显示透明
    }
  },
}
</script>


<style lang="scss" scoped>
.top-wrap { // 头部导航
  // width: 100%;
  .router-link-active {
    border-bottom: #4A7F95 2px solid;
    padding-bottom: 10px;
  }
  .main-wrap {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    .ant-menu {
      background-color: rgba(0, 0, 0, 0); // 导航部分使用透明底色
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .top-menu {
      font-size: 20px; 
      font-weight: 600;
      color:#4A7F95;
      padding-bottom: 5px;
    }
    .ant-menu-horizontal {
      border: none;
    }
  }
}
 
</style>