import axios from "@/api/axios";

// 首页
export const queryHomepage = data => axios({ url: "/homepageindex",  data });
export const fetchHome = data => axios({ url: "/api/hoome",  data });
// 荣誉
export const queryHonor = data => axios({ url: "/overview/honor",  data });
// 律师
export const queryLawyer = data => axios({ url: "/team/lawyer",  data });
// 律师详情
export const fetchLawyer = data => axios({ url: "/team/fetch/" + data });
// 新闻
export const queryNews = data => axios({ url: "/news/articles",  data });
// 律师详情
export const fetchNews = data => axios({ url: "/news/fetch/" + data });
// 招聘
export const queryJob = data => axios({ url: "/jobs/fetch",  data });
// 招聘详情
export const fetchJob = data => axios({ url: "/jobs/fetch/" + data });
// 专业领域
export const fetchBusinessList = data => axios({ url: "/business/fetch",  data });
// 领域详情
export const fetchBusinessDetail = id => axios({ url: "/business/fetch/" + id });
// 留言
export const leaveMessage = data => axios({ 
  url: "/aboutUs/leaveMessage",
  method: 'post',
  data,
});

// 律师信息
export const lawyerFetch = data => axios({ url: "/lawyer/fetch",  data });
export const lawyerFetchOne = id => axios({ url: "/lawyer/fetch/" + id });

// 新闻
export const newsFetch = data => axios({ url: "/news/fetch", data });
export const newsFetchOne = id => axios({ url: '/news/fetch/' + id});

// 招聘
export const jobFetch = data => axios({ url: '/jobs/fetch', data });
export const jobFetchOne = id => axios({ url: '/jobs/fetch/' + id});

// 首页内容
export const indexFetch = data => axios({ url: '/index/fetch', data});