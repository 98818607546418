<template>
  <div class="news-detail">
    <a class="back" @click="goBack"> 
      <svg t="1698137052863" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3439" width="14" height="14"><path d="M384 512L731.733333 202.666667c17.066667-14.933333 19.2-42.666667 4.266667-59.733334-14.933333-17.066667-42.666667-19.2-59.733333-4.266666l-384 341.333333c-10.666667 8.533333-14.933333 19.2-14.933334 32s4.266667 23.466667 14.933334 32l384 341.333333c8.533333 6.4 19.2 10.666667 27.733333 10.666667 12.8 0 23.466667-4.266667 32-14.933333 14.933333-17.066667 14.933333-44.8-4.266667-59.733334L384 512z" fill="#3E8FA2" p-id="3440"></path></svg>
      <span>返回列表</span>
    </a>
    <div class="content-wrap">
      <div class="title-box">
        <p class="title">{{ data.title }}</p>
        <p class="tips"><span>{{ data.category }}</span>|<span>{{ data.dateStr }}</span></p>
      </div>
      <div class="content-box" >
        <div v-html="data.content" ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import { newsFetchOne } from '@/api';

export default {
  name: 'news-detail',
  data(){
    const data = ref({});
    return { data };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      newsFetchOne(this.$route.params.id).then(res => {
        if (res.code === '10001') {
          this.data = res.data;
        }
      })
    },
    goBack() {
      this.$router.back();
    }
  }
}
</script>

<style lang="scss" scoped>
.news-detail {
  width: 1200px;
  margin: 0 auto 70px auto;
  text-align: left;
  .back {
    padding: 20px 0;
    display: flex;
    align-items: center;
    color: #3E8FA2;
    svg {
      margin-right: 2px;
    }
    span {
      line-height: 14px;
    }
  }
  .content-wrap {
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column; // 上下布局
    justify-content: center;
    padding: 10px 80px;
    p { margin: 0; }
    .title-box {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column; // 上下布局
      align-items: center;
      padding: 20px 0;
      border-bottom: #DEDEDE 1px solid;
      .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .tips {
        font-size: 14px;
        line-height: 14px;
        color: #666666;
        span {
          padding: 0 10px;
          height: 14px;
        }
        :first-child {
          color: #3E8FA2;
        }
      }
    }
    .content-box {
      padding: 20px 0;
      font-size: 18px;
      text-indent: 2em;	// 首行缩进
      img {
        margin: 10px auto;
      }
    }
  }
}
</style>