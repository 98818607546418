<template>
  <a-layout style="min-height: 100vh">
    <a-layout-header class="layout-header">
      <PageHeader />
    </a-layout-header>
    <a-layout-content class="layout-content-wrap">
      <router-view></router-view>
    </a-layout-content>
    <a-layout-footer>
      <Footer />
    </a-layout-footer>
  </a-layout>
  

</template>

<script>
import PageHeader from './components/PageHeader.vue'
// import Footer from './components/Footer.vue';
import Footer from './views/footer.vue';

export default {
  name: 'App',
  components: {
    PageHeader,
    Footer,
  },
}
</script>

<style lang="scss"> 

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  /** 站点文字设置 */
  color: #333333; // 字体颜色
  font-family: cursive; // 字体

  /** 默认背景 */
  background: #FFFFFF;

  /* margin-top: 60px; */
  margin: 0 auto;
  width: 100%;
  .ant-layout-footer{
    padding: 0;
  }

  .layout-header {
    // position: fixed;  // 固定布局
    top: 0; // 定位在顶部
    width: 100%;  // 宽度和浏览器同宽
    background-color: rgba(0, 0, 0, 0); 
    z-index: 99;
    // background: #FFFFFF;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }

  .page-container {
    padding-top: 50px;
    background-color: #fff; 
    min-height: calc(100vh - 110px);
    width: 1200px;
    margin: 0 auto;
  }
}
</style>
