<template>
  <div class="contractus-detail" >
    <div class="banner">
      <p>{{data.position}}</p>
      <p class="banner-infos">
        <span>
          <svg t="1698143541212" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8650" width="14" height="14"><path d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32z m-260 72h96v209.9L621.5 312 572 347.4V136z m220 752H232V136h280v296.9c0 3.3 1 6.6 3 9.3 5.1 7.2 15.1 8.9 22.3 3.7l83.8-59.9 81.4 59.4c2.7 2 6 3.1 9.4 3.1 8.8 0 16-7.2 16-16V136h64v752z" p-id="8651" fill="#ffffff"></path></svg>
          专业方向：{{data.professionalFields}}
        </span>
        <span>
          <svg t="1698142694135" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3597" width="14" height="14"><path d="M512 74.666667C317.866667 74.666667 160 234.666667 160 428.8c0 264.533333 320 484.266667 334.933333 492.8 6.4 4.266667 10.666667 6.4 17.066667 6.4s12.8-2.133333 17.066667-6.4c12.8-8.533333 334.933333-228.266667 334.933333-492.8C864 234.666667 706.133333 74.666667 512 74.666667z m0 782.933333c-66.133333-49.066667-288-228.266667-288-426.666667 0-160 130.133333-290.133333 288-290.133333s288 130.133333 288 290.133333c0 196.266667-221.866667 377.6-288 426.666667z" fill="#ffffff" p-id="3598"></path><path d="M512 309.333333c-76.8 0-138.666667 61.866667-138.666667 138.666667s61.866667 138.666667 138.666667 138.666667 138.666667-61.866667 138.666667-138.666667-61.866667-138.666667-138.666667-138.666667z m0 213.333334c-40.533333 0-74.666667-34.133333-74.666667-74.666667s34.133333-74.666667 74.666667-74.666667 74.666667 34.133333 74.666667 74.666667-34.133333 74.666667-74.666667 74.666667z" fill="#ffffff" p-id="3599"></path></svg>
          工作地点：{{data.workCity}}
        </span>
        <span>
          <svg t="1698143429510" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8400" width="14" height="14"><path d="M858.5 763.6c-18.9-44.8-46.1-85-80.6-119.5-34.5-34.5-74.7-61.6-119.5-80.6-0.4-0.2-0.8-0.3-1.2-0.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-0.4 0.2-0.8 0.3-1.2 0.5-44.8 18.9-85 46-119.5 80.6-34.5 34.5-61.6 74.7-80.6 119.5C146.9 807.5 137 854 136 901.8c-0.1 4.5 3.5 8.2 8 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c0.1 4.4 3.6 7.8 8 7.8h60c4.5 0 8.1-3.7 8-8.2-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z" p-id="8401" fill="#ffffff"></path></svg>
          招聘人数：{{data.quantity}}
        </span>
      </p>
    </div>
    <div class="main-wrap">
      <a class="back" @click="goBack"> 
        <svg t="1698137052863" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3439" width="14" height="14"><path d="M384 512L731.733333 202.666667c17.066667-14.933333 19.2-42.666667 4.266667-59.733334-14.933333-17.066667-42.666667-19.2-59.733333-4.266666l-384 341.333333c-10.666667 8.533333-14.933333 19.2-14.933334 32s4.266667 23.466667 14.933334 32l384 341.333333c8.533333 6.4 19.2 10.666667 27.733333 10.666667 12.8 0 23.466667-4.266667 32-14.933333 14.933333-17.066667 14.933333-44.8-4.266667-59.733334L384 512z" fill="#3E8FA2" p-id="3440"></path></svg>
        <span>返回列表</span>
      </a>
      <div class="content-wrap">
        <div class="content-box">
          <div class="title">职位要求</div>
          <div v-html="data.jobRequirements"></div>
        </div>
        <div class="content-box">
          <div class="title">工作要求</div>
          <div v-html="data.workRequirements"></div>
        </div>
        <div class="content-box">
          <div class="title">福利待遇</div>
          <div v-html="data.benefits"></div>
        </div>
        <a-button class="submit">投递简历</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import { jobFetchOne } from '@/api';


export default {
  name: 'contactus-detail',
  data(){
    const data = ref({});
    return { data };
  },
  mounted(){
    this.fetchData();
  },
  methods: {
    fetchData(){
      jobFetchOne(this.$route.params.id).then(res => {
        if (res.code === '10001') {
          this.data = res.data;
        }
      })
    },
    goBack() {
      this.$router.back();
    }
  }
}
</script>

<style lang="scss" scoped>
.contractus-detail {
  text-align: left;
  .banner {
    width: 100%;
    height: 300px;
    background: url('../assets/image/contactus-detail-banner.png');
    background-repeat: no-repeat;  // 不重复
    background-size: cover; // 铺满容器
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p { margin: 0;}
    p:first-child {
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .banner-infos {
      display: flex;
      align-items: center;
      span {
        margin: 0 30px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  .main-wrap {
    width: 1200px;
    margin: 0 auto 70px auto;
    text-align: left;
    .back {
      padding: 20px 0;
      display: flex;
      align-items: center;
      color: #3E8FA2;
      svg {
        margin-right: 2px;
      }
      span {
        line-height: 14px;
      }
    }
    .content-wrap {
      background: #FFFFFF;
      padding: 10px 120px;
      padding-bottom: 20px;
      text-align: center;
      border-radius: 10px;
      .content-box {
        display: flex;
        justify-content: space-between;
        text-align: left;
        border-bottom: #EEEEEE 1px solid;
        padding: 20px 0;
        .title {
          color: #3E8FA2;
          font-size: 24px;
          font-weight: 700;
          width: 200px;
        }
        :last-child {
          width: 100%;
          font-size: 16px;
          padding-top: 3px;
        }
      }
      .submit {
        background: #3E8FA2;
        color: #FFFFFF;
        font-size: 16px;
        width: 120px;
        height: 40px;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 30px
      }
    }
  }
}
</style>