<template>
  <div class="footer">
    <div class="content">
      <img src="../assets/image/footer-logo.png" class="logo"/>
      <div class="address">
        <p>联系我们</p>
        <p>地址：广州市天河区华夏路16号富力盈凯大厦4801  </p>
        <p>电话：020-38807524</p>
        <p>邮箱：gzfangxiao@163.com</p>
      </div>
      <img src="../assets/image/footer-wechat.jpg" class="wechat"/>
    </div>
    <span>Copyright © 2022 - 2030 | 方效律师事务所 | 
      <a target="_blank" href="https://beian.miit.gov.cn" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <img src="" style="float:left;"/>
            粤公网安备 44010602010965号
        </a ></span>
  </div>
</template>

<script>
export default {
  name: 'footer',
}
</script>

<style lang="scss">
.footer {
  height: 300px;
  width: 100%;
  background: url('../assets/image/footer-bg.png');
  background-repeat: no-repeat;  
  background-size: cover; 
  color: #FFFFFF;
  padding: 30px;

  .content {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;  // 中轴线对齐
    align-items: flex-end; // 子元素底部对齐

    .logo {
      width: 200px;
    }
    .address {
      text-align: left;
      padding-left: 100px;
      width: 500px;
      border-left: 1px solid #FFFFFF;
      margin: 0 100px;
      height: 140px;
      :first-child {
        font-size: 24px;
        font-weight: 700;
      }
      p {
        margin-bottom: 10px;
      }
    }
    .wechat {
      width: 130px;
    }
  }
}
</style>
