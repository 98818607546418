<template>
  <div >
    <a-row>
      <img src="../../assets/images/news-banner.png" />
    </a-row>
    <a-row style="padding: 50px " class="page-container">
      <a-col :span="24">
        <a-row justify="center" style="font-size: 20px; font-weight: 500;">
          {{ data.title }}
        </a-row>
        <a-row style="text-align: left; margin-top: 30px">
          <span v-html="data.content"></span>
        </a-row>
        <a-row style="margin-top: 20px" align="middle" justify="center">
          <span @click="goBack"><LeftOutlined style="margin-right: 5px;"/> 返回列表</span>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { ref } from 'vue'
import { fetchBusinessDetail } from '@/api';

import { LeftOutlined } from '@ant-design/icons-vue';

export default {
  name: 'business-detail',
  components: {
    LeftOutlined
  },
  setup() {
    const data = ref({});

    return {
      data
    }
  },
  mounted(){
    this.fetchData();
  },
  methods:{
    fetchData() {
      fetchBusinessDetail(this.$route.params.id).then(res => {
        if (res.code === '10001') {
          this.data = res.data;
        }
      })
    },
    goBack() {
      this.$router.back();
    }
  }
}
</script>