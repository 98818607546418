<template>
  <div class="page-wrap professional">
    <div class="banner-wrap">
      <div class="text">
        <span>业</span><span>务</span><span>领</span><span>域</span>
      </div>
    </div>
    <div class="content-wrap">
      <div v-for="(item, index) in data" :key="index">
        <div class="box">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from 'vue'
export default {
  name: 'professional',
  data(){
    const data = ref([]);
    return {
      data,
    }
  },
  mounted(){
    this.fetchData();
  },
  methods: {
    fetchData(){
      axios.get('/api/professional').then(res => {
        this.data = res.data?.data;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.professional {
  background: #F5F5F5;
  .banner-wrap {
    background: url('../assets/image/professional-banner.png');
    background-repeat: no-repeat;  // 不重复
    background-size: cover; // 铺满容器
  }

  .content-wrap {
    display: flex;
    justify-content: space-between; // 子元素两边布局
    flex-wrap: wrap;  // 自动换行
    
    .box {
      width: 350px;
      height: 120px;
      text-align: center;
      display: flex;
      justify-content: center;  // 中轴线对齐
      align-items: center;
      border-left: #3E8FA2 10px solid;
      font-size: 24px;
      font-weight: 500;
      background: #FFFFFF;
      margin: 15px 0;
    }
  }
}
</style>