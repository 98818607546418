<template>
  <!-- 第一部分 -->
  <div class="first-wrap">
    <a-carousel :autoplay="true" :interval="3000">
      <a-carousel-item>
        <img src="../assets/image/home-1-1.png"/>
      </a-carousel-item>
      <a-carousel-item>
        <img src="../assets/image/home-1-1.png"/>
      </a-carousel-item>
    </a-carousel>
    <div class="carousel-text">
      <div class="carousel-text-main">
        <span>勤勉务实·励精笃行</span>
      </div>
      <div class="carousel-text-bottom">
        <span>专业</span><span>高效</span><span>诚信</span><span>勤勉</span>
      </div>
    </div>
  </div>

  <!-- 第二部分 -->
  <div class="home-box second-wrap">
    <div class="content-box">
      <div class="content-box-title">
        <span class="title-text">
          <span>了解方效</span>
          <span>Understand fangxiao</span>
        </span>
        <button class="title-btn" @click="toIntroduce">查看更多</button>
      </div>
      <div class="home-box-main">
        <div class="child">
          <img src="../assets/image/home-2-1.png" />
          <div class="text">事在四方<br/>四方来效</div>
          <span>^</span>
        </div>
        <div class="child">
          <img src="../assets/image/home-2-2.png" />
          <div class="text">梦想汇聚<br/>绽放光芒</div>
          <span>^</span>
        </div>
        <div class="child">
          <img src="../assets/image/home-2-3.png" />
          <div class="text">成长赋能<br/>“家”衍生</div>
          <span>^</span>
        </div>
      </div>
    </div>
  </div>

  <!-- 第三部分-->
  <div class="home-box third-wrap">
    <div class="content-box">
      <div class="content-box-title">
        <div class="title-text">
          <span>专业领域</span>
          <span>Professional field</span>
        </div>
      </div>
      <div class="home-box-main">
        <div v-for="(item, index) in professionalField" :key="index" class="child">
          <div class="img-box">
            <img :src="item?.icon" />
          </div>
          <span>{{item?.title}}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- 第四部分 -->
  <div class="home-box four-wrap">
    <div class="content-box">
      <div class="content-box-title">
        <div class="title-text">
          <span>我们的团队</span>
          <span>Team of lawyers</span>
        </div>
        <button class="title-btn" @click="toTeam">查看更多</button>
      </div>
      <div class="home-box-main">
        <!-- <div class="stats">
          <div class="child">
            <span>人员规模</span>
            <span>40+</span>
          </div>
          <div class="child">
            <span>客户数量</span>
            <span>500+</span>
          </div>
          <div class="child">
            <span>代理案件</span>
            <span>10000+</span>
          </div>
        </div> -->
        <div class="carousel">
          <a-carousel
          ref="carousel"
          :autoplay="true" 
          :interval="3000"
          >
            <div  v-for="(item, index) in lawyers" :key="index">
              <div class="h-carousel-box">
                <img :src="item.photo">
                <div class="h-carousel-content">
                  <p class="name"><span>{{item.name}}</span><span>{{item.position}}</span></p>
                  <p>{{item.workCity}}</p>
                  <div class="introduce" v-html="item.personalProfile"></div>
                  <button @click="toTeamDetail(item.id)">联系我们</button>
                </div>
              </div>
            </div>
          </a-carousel>
        </div>
      </div>
    </div>
    <div class="options">
      <img src="../assets/image/home-left.png" @click="prev"/>
      <img src="../assets/image/home-right.png" @click="next"/>
    </div>
  </div>

  <!-- 第五部分 -->
  <div class="home-box five-wrap">
    <div class="content-box">
      <div class="content-box-title">
        <div class="title-text">
          <span>方效咨询</span>
          <span>News</span>
        </div>
        <button class="title-btn" @click="toNews">查看更多</button>
      </div>
      <div class="home-box-main">
        <div v-for="(item, index) in news" :key="index">
          <div class="news">
            <img :src="item.picture" class="img"/>
            <div class="news-content">
              <p class="news-type"><span>{{item.category}}</span><span>{{item.dateStr}}</span></p>
              <p class="title">{{item.title}}</p>
              <p class="content">{{item.summary}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

import { indexFetch } from '@/api';

export default {
  name: 'home',
  data() {
    const professionalField = ref([
      {
        name: '破产清算',
        icon: '../assets/image/home-3-1.png'
      },
      {
        name: '劳动争议纠纷',
        icon: '../assets/image/home-3-2.png'
      },
      {
        name: '民事商纠纷',
      },
      {
        name: '合同纠纷',
      },
      {
        name: '刑事辩护业务',
      },
      {
        name: '行政诉讼业务',
      },
      {
        name: '知识产权业务',
      },
      {
        name: '房地产业务',
      },
      {
        name: '婚姻家庭业务',
      },
    ]);
    const lawyers = ref([]);
    const news = ref([]);
    return {
      professionalField,
      lawyers,
      news,
    }
  },
  mounted(){
    this.fetchData();
  },
  methods: {
    fetchData() {
      indexFetch().then(res => {
        if (res.code === '10001') {
          const { lawyers, news, fields } = res.data;
          this.lawyers = lawyers;
          this.news = news;
          this.professionalField = fields;
        }
      })
    },
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
    toIntroduce() {
      this.$router.push({
        name: 'introduce',
      })
    },
    toTeam() {
      this.$router.push({
        name: 'team',
      })
    },
    toTeamDetail(id) {
      this.$router.push({
        name: 'teamDetail',
        params: {
          id: id,
        }
      })
    },
    toNews() {
      this.$router.push({
        name: 'news',
      })
    },
  }
}
</script>

<style lang="scss"> 
.home-box { /** 首页每个区块的统一样式 */
  height: 810px;  // 高度和浏览器同高
  width: 100%;  // 宽度撑满整个浏览器
  background: #FFFFFF;
  
  .content-box {
    width: 1200px;
    margin: 0 auto;
    padding: 100px 0;
  }
  
  .content-box-title { /** 区块内的标题部分样式 */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid gainsboro;
    margin-bottom: 40px; // 与下一个元素留出空间

    .title-text { /** 标题部分的文字部分 */
      align-items: flex-end;  // 底部对齐
      border-bottom: #3E8FA2 solid 2px;
      height: 100%;
      margin-bottom: -1px;  // 使底部边线重合
      padding-bottom: 20px;
      :first-child { /** 中文标题 */
        font-size: 32px;
        font-weight: 700;
        margin-right: 10px;
      }
      :last-child {  /** 英文标题 */
        font-size: 18px;
        font-weight: 500;
      }
    }

    .title-btn {
      width: 100px;
      height: 30px;
      color: #666666;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}

.first-wrap { // 首页第一部分容器
  // display: none;
  margin-top: -64px;   // 上移覆盖导航部分

  img: {  // 图片样式
    width: 100vw;  //图片和浏览器同宽
    height: 100vh; // 图片和浏览器同高
  }
  .ant-carousel .slick-slider .slick-track {
    height: 100vh;  // 将轮播图设置浏览器同高
  }
  .carousel-text {  // 文字区域
    position: absolute; // 相对定位
    top: 50%; // 上下居中
    left: 50%; // 左右居中
    transform: translate(-50%, -50%);
    text-align: center;
    color: white; // 字体使用白色
    width: 600px; // 文字部分长度
    .carousel-text-main { // 主体字部分
      display: flex;  // 采用flex布局，方便文字部署
      justify-content: space-between; // 会使得元素之间的空间相等
      align-items: center;  // 垂直方向居中
      overflow: hidden; // 隐藏超出容器部分的文本
      font-size: 40px;  // 字体大小
      font-weight: 600; // 加粗
      letter-spacing: 25px; 
      border-top: 1px solid white;//显示上边框
      border-bottom: 1px solid white;//显示下边框
      padding: 40px 0;
    }
    .carousel-text-main span {  
      width: 100%;  
      white-space: nowrap;  // 文本在一行内显示，而不是换行
      overflow: hidden;  
      text-overflow: ellipsis;  // 则会在文本超出长度时显示省略号
    }
    .carousel-text-bottom {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      padding: 20px 0;
    }
  }
}

/** 第二部分 */
.second-wrap {
  // display: none;
  .home-box-main {
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    font-size: 30px;

    .child {
      width: 33.3%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
      .text {
        margin-top: -80%;
        font-weight: 500;
        margin-bottom: 40%;
      }
    }
    
  }
}

/** 第三部分 */
.third-wrap {
  // display: none;
  background: url('../assets/image/home-3-bg.png');
  background-repeat: no-repeat;  
  background-size: cover; 
  color: #FFFFFF; // 文字为白色
  
  .home-box-main {
    display: flex;
    flex-wrap: wrap;  // 自动换行
    justify-content: center;  // 中轴线对齐

    .child {
      width: 20%;   // 每行最多5个
      padding: 40px 60px;
      font-size: 18px;
      .img-box {
        // height: 100px;
        padding: 10px;
        margin: 0 auto 10px auto;
        border-radius: 10px;
        background-color: rgba(0,0,0,0.15);
        img {
          width: 80px;
        }
      }
    }
  }
}

/** 第四部分 */
.four-wrap {
  // display: none;
  height: 700px;

  .content-box {
    padding-bottom: 0;
  }
  
  .stats {  /** 统计部分 */
    padding: 10px 140px;
    border-bottom: #999999 solid 2px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    .child {
      display: flex;
      flex-direction: column; // 纵向布局

      :first-child {
        font-size: 24px;
      }
      :last-child {
        font-size: 48px;
        font-weight: 700;
      }
    }
  }
  .carousel { /** 跑马灯 */
    .h-carousel-box {
      display: flex;
      justify-content: space-between;
      img {
        width: 360px;
        height: 400px;
      }
      .h-carousel-content {
        background-color: #F7F6F7;
        width: 100%;
        text-align: left;
        padding: 50px 40px;
        .name {
          height: 60px;
          font-weight: 700px;
          :first-child {
            font-size: 36px;
            margin-right: 20px;
          }
        }
        .introduce {
          height: 120px;
         // display: -webkit-box;  
         // -webkit-line-clamp: 2;  
          //-webkit-box-orient: vertical;  
          overflow: hidden;  
          text-overflow: ellipsis; 
          margin-bottom: 3px;
        }
        button {
          background: #3E8FA2;
          color: #FFFFFF;
          width: 120px;
          height: 40px;
          border: none;
        }
      }
    }
    
  }
  .options {
      height: 100px;
      background: linear-gradient(360deg, rgba(0,0,0,0.08) 0%, rgba(255,255,255,0) 100%);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;

      img {
        margin: 20px;
        width: 50px;
      }
    }
}

.five-wrap {
  background: #FFFFFF;
  .news {
    display: flex;
    margin-bottom: 20px;
    .img {
      width: 200px;
      height: 115px;
    }
    .news-content {
      width: 1000px;
      padding: 0 30px;
      text-align: left;
      .news-type {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        :first-child {
          color: #3E8FA2;
        }
      }
      .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 7px;
      }
      .content {  /** 最多显示两行，超出部分省略号代替 */
        display: -webkit-box;  
        -webkit-line-clamp: 2;  
        -webkit-box-orient: vertical;  
        overflow: hidden;  
        text-overflow: ellipsis;  
      }
    }
  }
}
</style>