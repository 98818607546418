<template>
  <div class="team-detail">
    <div class="team-main-wrap">
      <a class="back" @click="goBack"> 
        <svg t="1698137052863" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3439" width="14" height="14"><path d="M384 512L731.733333 202.666667c17.066667-14.933333 19.2-42.666667 4.266667-59.733334-14.933333-17.066667-42.666667-19.2-59.733333-4.266666l-384 341.333333c-10.666667 8.533333-14.933333 19.2-14.933334 32s4.266667 23.466667 14.933334 32l384 341.333333c8.533333 6.4 19.2 10.666667 27.733333 10.666667 12.8 0 23.466667-4.266667 32-14.933333 14.933333-17.066667 14.933333-44.8-4.266667-59.733334L384 512z" fill="#3E8FA2" p-id="3440"></path></svg>
        <span>返回列表</span>
      </a>
      <div class="content-wrap">
        <div class="top-wrap">
          <div class="photo-box"><img :src="data.photo" class="photo"/></div>
          <div class="basic-info">
            <p class="name"><span>{{ data.name }}</span><span>{{ data.position }}</span></p>
            <p class="tags">
              <span v-for="(item, index) in data.professionalFields" :key="index"> {{item}}</span>
            </p>
            <p>
              <svg t="1698134548615" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3439" width="16" height="16">
                <path d="M512 74.666667C317.866667 74.666667 160 234.666667 160 428.8c0 264.533333 320 484.266667 334.933333 492.8 6.4 4.266667 10.666667 6.4 17.066667 6.4s12.8-2.133333 17.066667-6.4c12.8-8.533333 334.933333-228.266667 334.933333-492.8C864 234.666667 706.133333 74.666667 512 74.666667z m0 782.933333c-66.133333-49.066667-288-228.266667-288-426.666667 0-160 130.133333-290.133333 288-290.133333s288 130.133333 288 290.133333c0 196.266667-221.866667 377.6-288 426.666667z" fill="#333333" p-id="3440"></path>
                <path d="M512 309.333333c-76.8 0-138.666667 61.866667-138.666667 138.666667s61.866667 138.666667 138.666667 138.666667 138.666667-61.866667 138.666667-138.666667-61.866667-138.666667-138.666667-138.666667z m0 213.333334c-40.533333 0-74.666667-34.133333-74.666667-74.666667s34.133333-74.666667 74.666667-74.666667 74.666667 34.133333 74.666667 74.666667-34.133333 74.666667-74.666667 74.666667z" fill="#333333" p-id="3441"></path>
              </svg>
              {{ data.workCity }}
            </p>
            <p>
              <svg t="1698135001958" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3654" width="16" height="16"><path d="M874.666667 181.333333H149.333333c-40.533333 0-74.666667 34.133333-74.666666 74.666667v512c0 40.533333 34.133333 74.666667 74.666666 74.666667h725.333334c40.533333 0 74.666667-34.133333 74.666666-74.666667V256c0-40.533333-34.133333-74.666667-74.666666-74.666667z m-725.333334 64h725.333334c6.4 0 10.666667 4.266667 10.666666 10.666667v25.6L512 516.266667l-373.333333-234.666667V256c0-6.4 4.266667-10.666667 10.666666-10.666667z m725.333334 533.333334H149.333333c-6.4 0-10.666667-4.266667-10.666666-10.666667V356.266667l356.266666 224c4.266667 4.266667 10.666667 4.266667 17.066667 4.266666s12.8-2.133333 17.066667-4.266666l356.266666-224V768c0 6.4-4.266667 10.666667-10.666666 10.666667z" fill="#333333" p-id="3655"></path>
              </svg>
              {{ data.email }}
            </p>
            <p>
              <svg t="1698135077754" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4020" width="16" height="16"><path d="M746.666667 949.333333H277.333333c-40.533333 0-74.666667-34.133333-74.666666-74.666666V149.333333c0-40.533333 34.133333-74.666667 74.666666-74.666666h469.333334c40.533333 0 74.666667 34.133333 74.666666 74.666666v725.333334c0 40.533333-34.133333 74.666667-74.666666 74.666666z m-469.333334-810.666666c-6.4 0-10.666667 4.266667-10.666666 10.666666v725.333334c0 6.4 4.266667 10.666667 10.666666 10.666666h469.333334c6.4 0 10.666667-4.266667 10.666666-10.666666V149.333333c0-6.4-4.266667-10.666667-10.666666-10.666666H277.333333z" fill="#333333" p-id="4021"></path><path d="M512 768m-42.666667 0a42.666667 42.666667 0 1 0 85.333334 0 42.666667 42.666667 0 1 0-85.333334 0Z" fill="#333333" p-id="4022"></path><path d="M597.333333 245.333333h-170.666666c-17.066667 0-32-14.933333-32-32s14.933333-32 32-32h170.666666c17.066667 0 32 14.933333 32 32s-14.933333 32-32 32z" fill="#333333" p-id="4023"></path></svg>
              {{ data.phone }}
            </p>
          </div>
        </div>
        <div class="bottom-wrap">
          <div class="bottom-box">
            <div class="title">个人介绍</div>
            <div class="content" v-html="data.personalProfile"></div>
          </div>
          <div class="bottom-box">
            <div class="title">经典案例</div>
            <div class="cases-content">
              <div v-for="(item, index) in data.cases" :key="index" v-html="item.introduction" class="case-box"/>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { lawyerFetchOne } from '@/api';


export default {
  name: 'team-detail',
  data() {
    const data = ref({});
    return { data };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      lawyerFetchOne(this.$route.params.id).then(res => {
        if (res.code === '10001') {
          let temp = res.data;
          temp = { ...temp, professionalFields: temp.professionalFields.split(',')}
          this.data = temp;
        }
      })
    },
    goBack() {
      this.$router.push({
        name: 'team',
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.team-main-wrap {
  width: 1200px;
  margin: 0 auto 70px auto;
  text-align: left;
  .back {
    padding: 20px 0;
    display: flex;
    align-items: center;
    color: #3E8FA2;
    svg {
      margin-right: 2px;
    }
    span {
      line-height: 14px;
    }
  }
  .content-wrap {
    background: #FFFFFF;
    border-radius: 10px;
    .top-wrap {
      background: #F9F9F9;
      height: 300px;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      border-radius: 10px;
      .photo-box {
        height: 100%;
        width: 250px;
        .photo {
          height: 100%;
          width: 100%;
        }
      }
      .basic-info {
        width: 100%;
        padding: 10px 60px;
        p {
          margin: 0;
          margin-bottom: 10px;
          align-items: center;
          display: flex;
          svg {
            margin-right: 10px;
          }
        }
        .name {
          :first-child {
            font-size: 36px;
          }
          :last-child {
            font-size: 20px;
            margin-left: 20px;
          }
        }
        .tags {
          margin-bottom: 14px;
          flex-wrap: wrap;
          span {
            margin-bottom: 6px;
            padding: 0 10px;
            font-size: 14px;
            font-weight: 400;
            color: #3E8FA2;
            line-height: 36px;
            background: rgba(62,143,162,0.1);
            margin-right: 10px;
          }
        }
      }
    }
    .bottom-wrap {
      padding: 10px 120px;
      .bottom-box {
        display: flex;
        // justify-content: space-between;
        text-align: left;
        border-bottom: #EEEEEE 1px solid;
        padding: 20px 0;
        .title {
          color: #3E8FA2;
          font-size: 24px;
          font-weight: 700;
          width: 200px;
        }
        .content {
          width: 100%;
          font-size: 18px;
          padding-top: 5px;
        }
        .cases-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          text-align: left;
          .case-box {
            background: rgba(62,143,162,0.1);
            margin: 3px 0;
            padding: 5px 10px;
            border-radius: 5px;
          }
        }
      }
      :last-child {
        border: none
      }
    }
  }
}
</style>