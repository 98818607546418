<template>
  <div class="page-wrap contactus">
    <div class="banner-wrap">
      <div class="text">
        <span>联</span><span>系</span><span>我</span><span>们</span>
      </div>
    </div>
    <div class="content-wrap">
      <div class="jobs-box">
        <div class="img"><span>最新招聘</span><span>Lastest Jobs</span></div>
        <div class="text">
          <p>方效倡导以人为本，视人才为最宝贵的资源，长期以来始终从国内外著名的法学院校中选拔优秀人才，组建成一支具备广博的知识底蕴、深厚的专业基础的人才队伍。 </p>
          <p>方效更注重人才培养，为确保高质量的法律服务，常年为所内律师提供专业培训讲座和研讨会，及时了解各个业务领域的最新专业成果和案例，不断丰富律师的执业经验，增强各业务部的执业技能。年轻律师和律师助理也将在此学习律师界前辈与同行的各种办案方法和技巧，不断夯实理论和经验基础，厚积薄发，为今后职业生涯打下坚实的基础。</p>
        </div>
      </div>
      <a-table :dataSource="data" :columns="columns" >
        <template #job="{ text, record }">
          <a @click="toDetail(record.id)">{{ text }}</a>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import { jobFetch } from '@/api';

const columns = [
        {
          title: '招聘职位',
          dataIndex: 'position',
          key: 'position',
          slots: { customRender: 'job' },
        }, 
        {
          title: '工作地点',
          dataIndex: 'workCity',
          key: 'address',
        }, 
        {
          title: '发布日期',
          dataIndex: 'date',
          key: 'date',
        }, 
        {
          title: '招聘人数',
          dataIndex: 'quantity',
          key: 'quantity',
        }, 
      ]

export default {
  name: 'contactus',
  data() {
    const data = ref([]);
    return {
      data,
      columns
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      jobFetch().then(res => {
        if (res.code === '10001') {
          this.data = res.data;
        }
      })
    },
    toDetail (id) {
      this.$router.push({
        name: 'job-detail',
        params: {
          id: id,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.contactus {
  .banner-wrap {
    background: url('../assets/image/contactus-banner.png');
    background-repeat: no-repeat;  // 不重复
    background-size: cover; // 铺满容器
  }

  .content-wrap {
    .jobs-box {
      height: 300px;
      display: flex;
      justify-content: space-between;
      color: #FFFFFF;
      margin-bottom: 40px;
      .img {
        width: 600px;
        background: url('../assets/image/contactus-1.png');
        background-repeat: no-repeat;  // 不重复
        background-size: cover; // 铺满容器
        display: flex;
        align-items: center;
        flex-direction: column; // 上下布局
        justify-content: center;  // 上下居中
        :first-child {
          font-size: 36px;
          font-weight: 700;
        }
        :last-child {
          font-size: 16px;
        }
      }
      .text {
        width: 100%;
        background-color: #3E8FA2;
        font-size: 18px;
        padding: 50px;
        text-align: left;
        text-indent: 2em;
      }
    }
  }
}
</style>