// 路由器
import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
// 引入组件
import Home from './views/home-new'
import Introduce from './views/p-introduce.vue'
import Business from './views/professional.vue'
import Team from './views/p-team.vue'
import News from './views/p-news.vue'
import Recruitment from './views/contactus.vue'
import TeamDetail from './views/team-detail.vue'
import NewsDetail from './views/news-detail.vue'
import JobDetail from './views/contactus-detail.vue'
import BusinessDetail from './views/business/Detail'


// 路由
const routes = [{
		path: '/',
		redirect: '/home'
	},
	{
		name: 'home',
		path: '/home',
		component: Home,
		meta: {
			title: '首页',
			isShow: true,
			icon: 'home-outlined'
		}
	},
	{
		name: 'introduce',
		path: '/introduce',
		component: Introduce,
		meta: {
			title: '方效概况',
			isShow: true,
			icon: 'home-outlined'
		},
	},
	{
		name: 'business',
		path: '/business',
		component: Business,
		meta: {
			title: '业务领域',
			isShow: true,
			icon: 'home-outlined'
		},
	},
	{
		name: 'business-detail',
		path: '/business-detail/:id',
		component: BusinessDetail,
	},
	{
		name: 'team',
		path: '/team',
		component: Team,
		meta: {
			title: '专业团队',
			isShow: true,
			icon: 'home-outlined'
		},
	},
	{
		name: 'teamDetail',
		path: '/team-detail/:id',
		component: TeamDetail,
	},
	{
		name: 'news',
		path: '/news',
		component: News,
		meta: {
			title: '新闻与文章',
			isShow: true,
			icon: 'home-outlined'
		},
		
	},
	{
		name: 'news-detail',
		path: '/news-detail/:id',
		component: NewsDetail
	},
	{
		name: 'recruitment',
		path: '/recruitment',
		component: Recruitment,
		meta: {
			title: '联系我们',
			isShow: true,
			icon: 'home-outlined'
		},
	},
	{
		name: 'job-detail',
		path: '/job-detail/:id',
		component: JobDetail
	},
]

// 创建一个路由器
const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

export default router
