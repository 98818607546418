<template>
  <div class="page-wrap news">
    <div class="banner-wrap">
      <div class="text">
        <span>新</span><span>闻</span><span>与</span><span>文</span><span>章</span>
      </div>
    </div>
    <div class="content-wrap">
      <a-tabs :centered="true" size="large" @change="fetchData">
        <a-tab-pane key="1" tab="方效动态">
          <div v-for="(item, index) in data" :key="index">
            <div class="news-box">
              <img :src="item.picture" />
              <div class="box-content">
                <p><span>{{item.category}}</span><span>{{item.dateStr}}</span></p>
                <p class="title" @click="toDetail(item.id)">{{item.title}}</p>
                <p class="new-content">{{item.summary}}</p>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="方效喜讯">
          <div v-for="(item, index) in data" :key="index">
            <div class="news-box">
              <img :src="item.picture" />
              <div class="box-content">
                <p><span>{{item.category}}</span><span>{{item.dateStr}}</span></p>
                <p class="title" @click="toDetail(item.id)">{{item.title}}</p>
                <p class="new-content">{{item.summary}}</p>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="方效务实">
          <div v-for="(item, index) in data" :key="index">
            <div class="news-box">
              <img :src="item.picture" />
              <div class="box-content">
                <p><span>{{item.category}}</span><span>{{item.dateStr}}</span></p>
                <p class="title" @click="toDetail(item.id)">{{item.title}}</p>
                <p class="new-content">{{item.summary}}</p>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="4" tab="方效研究">
          <div v-for="(item, index) in data" :key="index">
            <div class="news-box">
              <img :src="item.picture" />
              <div class="box-content">
                <p><span>{{item.category}}</span><span>{{item.dateStr}}</span></p>
                <p class="title" @click="toDetail(item.id)">{{item.title}}</p>
                <p class="new-content">{{item.summary}}</p>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import { newsFetch } from '@/api';

export default {
  name: 'news',
  data(){
    const data = ref([]);
    return { data };
  },
  mounted() {
    this.fetchData("1");
  },
  methods: {
    fetchData(params) {
      console.log(params);
      let category = params === "1" ? '方效动态' : params === "2" ? '方效喜讯' : params === "3" ? '方效务实' : '方效研究'
      newsFetch({category: category}).then(res => {
        if (res.code === '10001') {
          this.data = res.data;
        }
      })
    },
    toDetail (id) {
      this.$router.push({
        name: 'news-detail',
        params: {
          id: id,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.news {
  .banner-wrap {
    background: url('../assets/image/news-banner.png');
    background-repeat: no-repeat;  // 不重复
    background-size: cover; // 铺满容器
  }

  .content-wrap {
    .news-box {
      display: flex;
      justify-content: space-between;
      border-bottom: #DEDEDE 1px solid;
      padding: 20px 0;
      .box-content {
        width: 1000px;
        text-align: left;
        padding: 0 0 0 30px;
        
        :first-child {
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          :first-child {
            color: #3E8FA2;
          }
          :last-child {
            color: #666666;
          }
        }
        .title {
          font-size: 20px;
          font-weight: 700;
          margin: 7px 0;
        }
        p {
          margin: 0;
        }
        .new-content {
          display: -webkit-box;  
          -webkit-line-clamp: 2;  
          -webkit-box-orient: vertical;  
          overflow: hidden;  
          text-overflow: ellipsis;  
        }
      }
      img {
        width: 200px;
        height: 120px;
      }
    }
  }
}
</style>