<template>
  <div class="page-wrap team">
    <div class="banner-wrap">
      <div class="text">
        <span>专</span><span>业</span><span>团</span><span>队</span>
      </div>
    </div>
    <div class="content-wrap">
      <a-form :model="filterModel">
        <div class="content-form">
          <a-select class="input"
            v-model:value="filterModel.position"
            allowClear="true"
            placeholder="按职务查找"
          >
            <a-select-option value="合伙人律师">合伙人律师</a-select-option>
            <a-select-option value="专职律师">专职律师</a-select-option>
            <a-select-option value="兼职律师">兼职律师</a-select-option>
          </a-select>
          <a-input class="input"
            v-model:value="filterModel.name"
            placeholder="按姓名查找"
          />
          <a-select class="input"
            v-model:value="filterModel.workCity"
            allowClear="true"
            placeholder="按工作地查找"
          >
            <a-select-option value="中国, 广州">中国, 广州</a-select-option>
          </a-select>
          <a-button class="button" @click="fetchData">查找</a-button>
        </div>
      </a-form>
      <div class="content-view">
        <a-list item-layout="vertical" size="large" :pagination="pagination" :data-source="data">
            <template #renderItem="{ item }">
              <a-list-item key="item.id">
                <div class="view-box">
                  <div class="photo-box"><img :src="item.photo" /></div>
                  <div class="introduce">
                    <p class="name"><span>{{item.name}}</span><span>{{item.position}}</span></p>
                    <p>{{item.workCity}}</p>
                    <p>{{item.professionalFields}}</p>
                  </div>
                  <a-button class="button" @click="toDetail(item.id)">查看详情</a-button>
                </div>
              </a-list-item>
            </template>
        </a-list>
        <!-- <div v-for="(item, index) in data" :key="index">
          
          <div class="view-box">
            <div class="photo-box"><img :src="item.photo" /></div>
            <div class="introduce">
              <p class="name"><span>{{item.name}}</span><span>{{item.position}}</span></p>
              <p>{{item.workCity}}</p>
              <p>{{item.professionalFields}}</p>
            </div>
            <a-button class="button" @click="toDetail(item.id)">查看详情</a-button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import { lawyerFetch } from '@/api';

export default {
  name: 'team',
  data() {
    const data = ref([]);
    const filterModel = {
      position: null,
      name: '',
      workCity: null,
    }
    const pagination = {
      onChange: (page) => {
        pagination.current = page,
        this.fetchData();
      },
      pageSize: 10,
      current: 1,
      total: 100,
    }
    return { data, filterModel, pagination };
  },
  mounted(){
    this.fetchData();
  },
  methods: {
    fetchData() {
      lawyerFetch({...this.filterModel, ...this.pagination}).then(res => {
        if (res.code === '10001') {
          this.data = res.data;
          this.pagination.total = res.total;
        }
      })
    },
    toDetail (id) {
      this.$router.push({
        name: 'teamDetail',
        params: {
          id: id,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.team {
  .ant-list-lg .ant-list-item {
    padding: 0;
  }
  .ant-list-split .ant-list-item {
    border: none;
  }
  .ant-list-item {
    padding: 0;
  }

  .banner-wrap {
    background: url('../assets/image/team-banner.png');
    background-repeat: no-repeat;  // 不重复
    background-size: cover; // 铺满容器
  }

  .content-wrap {
    .content-form {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .input {
        width: 350px;
      }
    }
    .content-view {
      width: 100%;
      padding: 20px 0;
      .view-box {
        width: 100%;
        height: 240px;
        background: #F5F5F5;
        margin: 20px 0;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .introduce {
          text-align: left;
          width: 100%;
          padding-left: 50px;
          font-size: 18px;
          .name {
            :first-child {
              font-size: 30px;
              font-weight: 700;
            }
            :last-child {
              margin-left: 10px;
              font-size: 20px;
              font-weight: 700;
            }
          }
        }
        .photo-box {
          width: 180px;
          height: 200px;
          img {
            width: 180px;
            height: 200px;
          }
        }
      }
    }
    .button {
      width: 120px;
      // height: 40px;
      background: #3E8FA2;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: 1px solid #3E8FA2;
      color: #FFFFFF;
    }
  }
}
</style>