import { createApp } from 'vue'
// 引入antd
import Antd from 'ant-design-vue';
// 引入路由器
import router from './router'
import App from './App.vue'
// 引入antd样式
// import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.less';
// 自定义布局样式
import './assets/css/layout.scss';

// 引入mock数据
import './api/mock.js';

const app = createApp(App);

// 注册antd
app.use(Antd).use(router).mount('#app')
