import { Toast } from 'vant';

export default param => {
  const { type, message } = param;
  switch (type){
    case 'success':
      Toast({
        message,
        // icon: '/images/notify-success.png',
      });
      break;
    case 'danger':
      Toast({
        message,
        // icon: '/images/notify-error.png',
      });
      break;
    default:
      Toast(message);
      break;
  }
};